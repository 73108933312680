<template>
  <div
    class="absolute bottom-0 left-0 mx-2 mb-12 text-black flex z-50 text-xs select-none"
    style="z-index: 999;"
  >
    <div class="block my-2 bg-white rounded-lg p-2">
      <div
        class="hover:underline cursor-pointer text-gray-800 text-left"
        @click.prevent.stop="close"
      >
        Close
        <fa-icon
          icon="xmark"
          class="fa-fw"
        />
      </div>

      <h5 class="font-bold uppercase mt-1 pt-1 border-t border-gray-400 text-gray-600 leading-loose">
        Animations
      </h5>
      <div class="-mx-1 my-1">
        <template v-for="animation in animations">
          <div
            class="flex-1 btn btn-xs my-1 p-1 px-2 mx-1 text-center"
            :class="{ 'positive' : isActive(animation.name) }"
            @click.prevent="viewPlaybackContent(animation.name)"
          >
            {{ animation.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import menuMixin from './menuMixin.js'

export default {
  name: 'ViewerPlaybackMenu',

  mixins: [menuMixin],

  props: {

    animations: {
      type: Array,
      default: []
    },

    activeAnimation: {
      type: Object,
      default: null
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    isActive (name) {
      return (this.activeAnimation !== null && this.activeAnimation.name === name)
    },

    viewPlaybackContent (name) {
      this.$emit('view-playback-content', name)
    }
  }

}
</script>
